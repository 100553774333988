/**
 * DESCRIPTION: This is the main App component for the react app. This component is responsible for rendering the app's routes and handling user authentication. It also fetches user data from the Static Web Apps authentication endpoint and sends a sign-in log to the API endpoint.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import api, { apiInstance } from "./services/api.service";
import EnvironmentRoutes from "./routes/environment.routes";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Redux Objects

import { RootState, AppDispatch } from "./redux";
import { setTheme } from "./redux/theme.slice"; // -- Manage the active theme state
import { fetchUserPrincipal, fetchUserData, fetchUserPhoto } from "./redux/user.slice"; // -- Import the user slice actions
import { fetchOnPremesisSyncStatus } from "./redux/onPremesisSyncStatus.slice";
import { setEnvironment } from "./redux/environment.slice";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Components

import Login from "./pages/Login"; // -- Import the login page
import Layout from "./layout"; // -- Import the layout component for access to the protected routes
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import Types

type SignInLog = Parameters<typeof api.postSignInLog>[0]; // -- Import the SignInLog type
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the App component

export default function App() {
  // -- Initialize functions and Redux objects
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user);
  const isAuthenticated = Boolean(user.principal);
  const [currentTime, setCurrentTime] = useState(Date.now()); // eslint-disable-line

  // Initialize the current site's environment based on window.location
  useEffect(() => {
    const activeEnvironment = EnvironmentRoutes.find((route) => window.location.href.includes(route.url));
    if (activeEnvironment) {
      dispatch(setEnvironment(activeEnvironment));
    }
  }, [dispatch]);

  // -- Update the current time every second (Ensures timings are always up-to-date)
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  // Send a sign-in log to the API endpoint
  const sendSignInLog = useCallback(async () => {
    try {
      // Verify that the user ID is available
      const userId = user?.principal?.userId;
      if (!userId) {
        console.warn(`Unable to send sign-in log: User ID not available in auth response.`);
        return;
      }

      // Build the payload for the sign-in log
      const timestamp = new Date().toISOString();

      // Fetch the user's IP address from the claims or fallback to IPify API
      let ip_address = user?.principal?.claims?.find((claim) => claim.typ === "ipaddr")?.val || null;

      if (!ip_address) {
        try {
          const response = await fetch("https://api.ipify.org?format=json");
          const data = await response.json();
          ip_address = data.ip;
        } catch (error) {
          console.error("Failed to fetch IP address:", error);
          ip_address = "Unknown";
        }
      }

      const payload: SignInLog = {
        userId,
        timestamp,
        ip_address,
      };

      // Send the payload to the API endpoint
      await api.postSignInLog(payload);
    } catch (error) {
      console.error("Failed to send sign-in log:", error);
    }
  }, [user?.principal?.userId, user?.principal?.claims]);

  // Send sign-in log when the user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
      sendSignInLog();
    }
    // Only run once when the user is authenticated
  }, [isAuthenticated, sendSignInLog]);

  // Send a heartbeat every 3 minutes if the user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
      const interval = setInterval(async () => {
        const userId = user?.principal?.userId;
        if (userId) {
          try {
            await api.postHeartbeat(userId);
          } catch (error) {
            console.error("Failed to send heartbeat:", error);
          }
        }
      }, 180000); // -- 3 minutes

      // Call immediately to send the first heartbeat
      api.postHeartbeat(user?.principal?.userId);

      return () => clearInterval(interval);
    }
  }, [isAuthenticated, user?.principal?.userId]);

  // Fetch the organization's on-premises sync status every 5 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchOnPremesisSyncStatus());
    }, 300000); // -- 5 minutes

    // Call immediately to fetch the status
    dispatch(fetchOnPremesisSyncStatus());

    return () => clearInterval(interval);
  }, [dispatch]);

  // Fetch user info and initialize on component mount
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.getAuthMe();
        if (response.status === 200) {
          const data = response.data;
          if (data?.clientPrincipal) {
            await dispatch(fetchUserPrincipal()).unwrap();
            await dispatch(fetchUserData()).unwrap();

            await dispatch(fetchUserPhoto());
          }
        } else {
          console.error("Failed to fetch user info:", response.statusText);
        }
      } catch (error) {
        console.error("Failed to fetch user info:", error);
      }
    };
    const initialize = async () => {
      if (!user.principal || !user.data || !user.photo) {
        await fetchUser();
      }
    };
    initialize();
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update API headers with the user's details when the user changes
  useEffect(() => {
    const claimsGivenName = user?.principal?.claims?.find((claim) => claim.typ === "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname")?.val;
    const claimsSurname = user?.principal?.claims?.find((claim) => claim.typ === "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname")?.val;
    const claimsFullName = claimsGivenName && claimsSurname ? `${claimsGivenName} ${claimsSurname}` : null;

    apiInstance.defaults.headers.common["X-User-Id"] = user?.principal?.userId || user?.data?.id;
    apiInstance.defaults.headers.common["X-User-Upn"] = user?.principal?.userDetails || user?.data?.userPrincipalName;
    apiInstance.defaults.headers.common["X-User-Name"] = claimsFullName || `${user?.data?.givenName} ${user?.data?.surname}`;
  }, [user]);

  // -- Listen for theme changes and update the redux state
  useEffect(() => {
    const updateTheme = () => {
      const theme = document.documentElement.getAttribute("data-theme") || "dark"; // -- Default to dark theme
      dispatch(setTheme(theme));
    };

    const observer = new MutationObserver(() => {
      updateTheme();
    });

    observer.observe(document.documentElement, { attributes: true, attributeFilter: ["data-theme"] });

    return () => {
      observer.disconnect();
    };
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        {/* Conditionally render login page or redirect to dashboard if authenticated */}
        <Route path="/login" element={isAuthenticated ? <Navigate to="/portal/dashboard" replace /> : <Login />} />

        {/* Protected user routes */}
        <Route path="/portal/*" element={<Layout />} />

        {/* Redirect all other routes */}
        <Route path="*" element={<Navigate to={isAuthenticated ? "/portal/dashboard" : "/login"} replace />} />
      </Routes>
    </BrowserRouter>
  );
}
