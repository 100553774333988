/**
 * DESCRIPTION: This is a slice that contains the on-premeses AD Sync Status.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import api from "../services/api.service";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define types and interfaces

interface StatisticsState {
  data: Partial<microsoftgraph.Organization>;
  status: "idle" | "loading" | "succeeded" | "failed"; // Track the status of the API call
  error: string | null;
}
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the initial state

const initialState: StatisticsState = {
  data: null,
  status: "idle",
  error: null,
};
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the async thunk to fetch the statistics

export const fetchOnPremesisSyncStatus = createAsyncThunk("onPremesisSyncStatus/fetch", async () => {
  try {
    const response = await api.getOnPremesisSyncStatus();

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch statistics");
  }
});
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the slice

const onPremesisSyncStatusSlice = createSlice({
  name: "onPremesisSyncStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOnPremesisSyncStatus.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchOnPremesisSyncStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchOnPremesisSyncStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch statistics";
      });
  },
});
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Export the reducer and actions

export default onPremesisSyncStatusSlice.reducer;
