/**
 * DESCRIPTION: This is the routes file for the react app. This file contains the routes that are used to render the app's views. Each route object contains the path and component to render, as well as the roles that are allowed to access the route.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import { lazy } from "react";
import { APPLICATION_ROLES } from "../utils/globalConstantUtil";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// -- Define the pages

const Dashboard = lazy(() => import("../pages/protected/Dashboard"));
const Analytics = lazy(() => import("../pages/protected/Analytics"));
const ApiStatistics = lazy(() => import("../pages/protected/ApiStatistics"));
const RedisStatistics = lazy(() => import("../pages/protected/RedisStatistics"));
const UserManagement = lazy(() => import("../pages/protected/settings/PortalUsers"));
const MigrationAgentSettings = lazy(() => import("../pages/protected/settings/MigrationAgent"));
const Devices = lazy(() => import("../pages/protected/Devices"));
const Device = lazy(() => import("../pages/protected/Device"));
const resourceCosts = lazy(() => import("../pages/protected/settings/resourceCosts"));
const auditLogs = lazy(() => import("../pages/protected/AuditLogs"));
const windows11 = lazy(() => import("../pages/protected/Windows11"));
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// -- Define the role names here to avoid duplication

// -- Define the routes
const routes = [
  {
    path: "dashboard",
    component: Dashboard,
    allowedRoles: [APPLICATION_ROLES.Engineer, APPLICATION_ROLES.Tester, APPLICATION_ROLES.Reader],
  },
  {
    path: "devices",
    component: Devices,
  },
  {
    // -- The device page uses the serial number as a parameter
    path: "devices/:serial",
    component: Device,
  },
  {
    path: "analytics",
    component: Analytics,
  },
  {
    path: "admin/api-statistics",
    component: ApiStatistics,
    allowedRoles: [APPLICATION_ROLES.Administrator],
  },
  {
    path: "admin/redis-statistics",
    component: RedisStatistics,
    allowedRoles: [APPLICATION_ROLES.Administrator],
  },
  {
    path: "admin/audit-logs",
    component: auditLogs,
    allowedRoles: [APPLICATION_ROLES.Administrator],
  },
  {
    path: "admin/windows-11",
    component: windows11,
    allowedRoles: [APPLICATION_ROLES.Administrator],
  },
  {
    path: "admin/settings/migration-agent",
    component: MigrationAgentSettings,
    allowedRoles: [APPLICATION_ROLES.Administrator],
  },
  {
    path: "admin/settings/user-management",
    component: UserManagement,
    allowedRoles: [APPLICATION_ROLES.Administrator],
  },
  {
    path: "admin/settings/resource-costs",
    component: resourceCosts,
    allowedRoles: [APPLICATION_ROLES.Administrator],
  },
];

export default routes;
