/**
 * DESCRIPTION: This is a service file that contains the API calls for the application.
 *              The API Type definitions are imported from the API folder to ensure that the API calls are correctly typed.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import axios from "axios";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import type definitions

import { AxiosResponse } from "axios";
import { UserPrincipalResponse } from "../types/userPrincipal";
import { User, Device, Group } from "@microsoft/microsoft-graph-types";
import * as apiTypes from "../../../api/src/types";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the API service

export const apiInstance = axios.create({
  baseURL: "/api/protected",
  headers: {
    "Content-Type": "application/json",
  },
});

const api = {
  // Static Web App authentication endpoint
  getAuthMe: (): Promise<AxiosResponse<UserPrincipalResponse>> => axios.get("/.auth/me"), // This is called from axios directly as it is a static web app endpoint and not part of our api

  // Entra ID On-Prem Sync Status
  getOnPremesisSyncStatus: (): Promise<AxiosResponse<Partial<microsoftgraph.Organization>>> => apiInstance.get("/onPremesisSyncStatus"),

  // User-related API routes
  getUser: (id: string): Promise<AxiosResponse<User>> => apiInstance.get(`/entraIdUser/${id}`),
  getUserPhoto: (id: string): Promise<AxiosResponse<Blob>> => apiInstance.get(`/entraIdUserPhoto/${id}`, { responseType: "blob" }),

  // Group related API routes
  getGroup: (id: string): Promise<AxiosResponse<Group>> => apiInstance.get(`/entraIdGroup/${id}`),
  getGroupByDisplayName: (displayName: string): Promise<AxiosResponse<Group[]>> => apiInstance.get(`/entraIdGroupByDisplayName/${displayName}`),

  // POST for logging sign-ins
  postSignInLog: (data: apiTypes.signInLog) => apiInstance.post(`/signInLog`, data),
  postHeartbeat: (userId: string) => apiInstance.post(`/heartbeat/${userId}`),

  // Search-related API routes
  searchDevices: (searchString: string): Promise<AxiosResponse<Device[]>> => apiInstance.get(`/searchEntraIdDevices/${searchString}`),
  searchUsers: (searchString: string): Promise<AxiosResponse<User[]>> => apiInstance.get(`/searchEntraIdUsers/${searchString}`),

  // Admin API routes
  admin: {
    getSignInLogs: (userId: string): Promise<AxiosResponse<apiTypes.signInLog[]>> => apiInstance.get(`/admin/signInLogs/${userId}`),
    getResourceCosts: (params: apiTypes.ResourceCostQueryParams): Promise<AxiosResponse<apiTypes.ResourceCostsResponse>> => apiInstance.get(`/admin/resourceCosts`, { params }),
    getAppRoles: (): Promise<AxiosResponse<apiTypes.appRole[]>> => apiInstance.get(`/admin/appRoles`),
    getApiStatistics: (params: apiTypes.APIStatisticsParams): Promise<AxiosResponse<apiTypes.APIStatisticsResponse>> => apiInstance.get(`/admin/apiStatistics`, { params }),
    getAuditLogs: (params: apiTypes.AuditLogsQueryParams): Promise<AxiosResponse<apiTypes.AuditLogsResponse>> => apiInstance.get(`/admin/auditLogs`, { params }),
    getRedisStatistics: (): Promise<AxiosResponse<apiTypes.RedisStatisticsResponse>> => apiInstance.get(`/admin/redisStatistics`),
    migrationAgentSettings: {
      DeploymentGroups: {
        get: (): Promise<AxiosResponse<apiTypes.DeploymentGroups>> => apiInstance.get(`/admin/migrationAgentSettings/DeploymentGroups`),
        post: (data: apiTypes.DeploymentGroups) => apiInstance.post(`/admin/migrationAgentSettings/DeploymentGroups`, data),
      },
      HardwareRequirements: {
        get: (): Promise<AxiosResponse<apiTypes.HardwareRequirements>> => apiInstance.get(`/admin/migrationAgentSettings/HardwareRequirements`),
        post: (data: apiTypes.HardwareRequirements) => apiInstance.post(`/admin/migrationAgentSettings/HardwareRequirements`, data),
      },
      PreProvisioning_GoLiveOffset: {
        get: (): Promise<AxiosResponse<apiTypes.PreProvisioningGoLiveOffset>> => apiInstance.get(`/admin/migrationAgentSettings/PreProvisioning_GoLiveOffset`),
        post: (data: apiTypes.PreProvisioningGoLiveOffset) => apiInstance.post(`/admin/migrationAgentSettings/PreProvisioning_GoLiveOffset`, data),
      },
      Provisioning_NetworkSpeedRequirements: {
        get: (): Promise<AxiosResponse<apiTypes.ProvisioningNetworkSpeedRequirements>> => apiInstance.get(`/admin/migrationAgentSettings/Provisioning_NetworkSpeedRequirements`),
        post: (data: apiTypes.ProvisioningNetworkSpeedRequirements) => apiInstance.post(`/admin/migrationAgentSettings/Provisioning_NetworkSpeedRequirements`, data),
      },
      DeploymentAuthorisation: {
        get: (): Promise<AxiosResponse<apiTypes.DeploymentAuthorisation>> => apiInstance.get(`/admin/migrationAgentSettings/DeploymentAuthorisation`),
        post: (data: apiTypes.DeploymentAuthorisation) => apiInstance.post(`/admin/migrationAgentSettings/DeploymentAuthorisation`, data),
      },
    },
    portalUsers: {
      get: (): Promise<AxiosResponse<apiTypes.portalUser[]>> => apiInstance.get(`/admin/portalUsers`),
      post: (data: apiTypes.portalUserPostBody) => apiInstance.post(`/admin/portalUsers`, data),
      delete: (userId: string) => apiInstance.delete(`/admin/portalUsers/${userId}`),
      listOnline: (): Promise<AxiosResponse<string[]>> => apiInstance.get(`/admin/portalUsers/online`),
    },
    devices: {
      delete: (serial: string): Promise<AxiosResponse> => apiInstance.delete(`/admin/devices/${serial}`),
    },

    windows11Upgrade: {
      stats: {
        get: (): Promise<AxiosResponse<apiTypes.Windows11Upgrade_Stats>> => apiInstance.get(`/admin/windows11Upgrade/stats`),
      },
      runs: {
        get: (): Promise<AxiosResponse<apiTypes.Windows11Upgrade_Run[]>> => apiInstance.get(`/admin/windows11Upgrade/runs`),
      },
      devices: {
        get: (limit: number, offset: number, search?: string): Promise<AxiosResponse<apiTypes.Windows11Upgrade_DeviceResponse>> => apiInstance.get(`/admin/windows11Upgrade/devices`, { params: { search, limit, offset } }),
      },
      settings: {
        get: (): Promise<AxiosResponse<apiTypes.Windows11Upgrade_Settings>> => apiInstance.get(`/admin/windows11Upgrade/settings`),
        patch: (data: apiTypes.Windows11Upgrade_Settings): Promise<AxiosResponse<apiTypes.Windows11Upgrade_Settings>> => apiInstance.patch(`/admin/windows11Upgrade/settings`, data),
      },
    },
  },

  // Tester API routes
  tester: {
    devices: {
      delete: (serial: string): Promise<AxiosResponse> => apiInstance.delete(`/tester/devices/${serial}`),
    },
  },

  // Common API routes
  devices: {
    list: (params: apiTypes.DevicesQueryParams): Promise<AxiosResponse<apiTypes.DevicesResponse>> => apiInstance.get(`/devices`, { params }),
    get: (serial: string): Promise<AxiosResponse<apiTypes.DeviceRecord>> => apiInstance.get(`/devices/${serial}`),

    // -- Device overrides
    overrides: {
      post: (serial: string, data: apiTypes.DeviceOverride): Promise<AxiosResponse> => apiInstance.post(`/devices/${serial}/overrides`, data),
    },

    // -- Device log transcripts
    logTranscripts: {
      list: (serial: string): Promise<AxiosResponse<apiTypes.LogFile[]>> => apiInstance.get(`/logtranscripts/${serial}`),
      get: (path: string): Promise<AxiosResponse> => apiInstance.get(`/logtranscripts/${path}`),
    },

    // -- API Invocations
    apiInvocations: {
      list: (serial: string, params: apiTypes.ApiInvocationsParams): Promise<AxiosResponse<apiTypes.ApiInvocationsResponse>> => apiInstance.get(`/apiInvocations/${serial}`, { params }),
    },
  },
  migrationAgentSettings: {
    DeploymentAuthorisation: {
      get: (): Promise<AxiosResponse<apiTypes.DeploymentAuthorisation>> => apiInstance.get(`/migrationAgentSettings/DeploymentAuthorisation`),
    },
  },

  getStatistics: (): Promise<AxiosResponse<apiTypes.StatisticsResponse>> => apiInstance.get(`/statistics`),
  getAnalytics: (): Promise<AxiosResponse<apiTypes.AnalyticsResponse>> => apiInstance.get(`/analytics`),
  getInvocationLogs: (invocationId: string): Promise<AxiosResponse<apiTypes.InvocationLogsResponse>> => apiInstance.get(`/invocationLogs/${invocationId}`),
  getEdpSyncStatus: (): Promise<AxiosResponse<apiTypes.EDPSyncStatusResponse>> => apiInstance.get(`/edpSyncStatus`),
};

export default api;
