/**
 * DESCRIPTION: This is a utility file that contains a function to format the duration since a timestamp to a human-readable format.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import moment from "moment";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the function

// -- Helper function to format the last seen timestamp
export const formatTimeSince = (timestamp: string, showSeconds?: boolean) => {
  const now = moment();
  const startTime = moment(timestamp);
  const diffInSeconds = now.diff(startTime, "seconds");

  if (diffInSeconds < 0) {
    // Future date handling
    const absDiffInSeconds = Math.abs(diffInSeconds);

    if (absDiffInSeconds < 60) {
      return `In a few seconds`;
    } else if (absDiffInSeconds < 3600) {
      const diffInMinutes = Math.ceil(absDiffInSeconds / 60);
      return `In ${diffInMinutes} min${diffInMinutes > 1 ? "s" : ""}`;
    } else if (absDiffInSeconds < 86400) {
      const diffInHours = Math.ceil(absDiffInSeconds / 3600);
      return `In ${diffInHours} hour${diffInHours > 1 ? "s" : ""}`;
    } else if (absDiffInSeconds < 2592000) {
      const diffInDays = Math.ceil(absDiffInSeconds / 86400);
      return `In ${diffInDays} day${diffInDays > 1 ? "s" : ""}`;
    } else if (absDiffInSeconds < 31536000) {
      const diffInMonths = now.diff(startTime, "months") * -1; // Invert for future
      return `In ${diffInMonths} month${diffInMonths > 1 ? "s" : ""}`;
    } else {
      const diffInYears = now.diff(startTime, "years") * -1; // Invert for future
      return `In ${diffInYears} year${diffInYears > 1 ? "s" : ""}`;
    }
  } else {
    // Past date handling
    if (diffInSeconds < 5 || (diffInSeconds < 60 && !showSeconds)) {
      return `Just Now`;
    } else if (diffInSeconds < 60 && showSeconds) {
      return `${diffInSeconds} sec${diffInSeconds > 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < 3600) {
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      return `${diffInMinutes} min${diffInMinutes > 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < 86400) {
      const diffInHours = Math.floor(diffInSeconds / 3600);
      return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < 2592000) {
      const diffInDays = Math.floor(diffInSeconds / 86400);
      return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < 31536000) {
      const diffInMonths = now.diff(startTime, "months");
      return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""} ago`;
    } else {
      const diffInYears = now.diff(startTime, "years");
      return `${diffInYears} year${diffInYears > 1 ? "s" : ""} ago`;
    }
  }
};
