/**
 * DESCRIPTION: This is the modal body component that displays a confirmation message and two buttons to confirm or cancel the action.
 *
 * Author: Dean Longstaff (dean.longstaff@justice.gov.uk)
 */
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import the required modules

import Datepicker from "react-tailwindcss-datepicker";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DeviceRecord } from "../../../../../api/src/types";
import { RootState } from "../../../redux";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Import functions

import { showToastNotification } from "../../../utils/toastNotification";
import { updateDeviceOverrides } from "../utils/updateDeviceOverrides";
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ----- Define the modal body component

interface ModalProps {
  closeModal: () => void;
  extraObject: {
    refresh: () => void;
    device: DeviceRecord;
  };
}

export default function DeviceOverridesModalBody({ closeModal, extraObject }: ModalProps) {
  const { device } = extraObject;
  const environment = useSelector((state: RootState) => state.environment)?.state;

  // Function to parse a date string into a Date object
  const parseDate = (dateString: string | null | undefined | ""): Date | null => {
    if (!dateString || dateString === "") return null; // Handle null or undefined
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date; // Validate and return null for invalid dates
  };

  // -- Initialise the state for the device overrides
  const [goLiveDate, setDeviceGoLiveDate] = useState(parseDate(device?.Overrides?.GoLiveDate || device?.GoLiveDate));
  const [deploymentRelease, setDeploymentRelease] = useState(device?.Overrides?.DeploymentRelease?.toUpperCase() || device?.DeploymentRelease?.toUpperCase() || "");
  const [targetOperatingSystem, setTargetOperatingSystem] = useState(device?.Overrides?.ISORequired?.toUpperCase() || device?.ISORequired?.toUpperCase() || "");
  const [replacementSerialNumber, setReplacementSerialNumber] = useState(device?.Overrides?.ReplacementSerialNumber || device?.ReplacementSerialNumber || "");

  // -- Track if the values have changed from the current values to determine if the save button should be enabled
  const [isGoLiveDateChanged, setIsGoLiveDateChanged] = useState(false);
  const [isDeploymentReleaseChanged, setIsDeploymentReleaseChanged] = useState(false);
  const [isTargetOperatingSystemChanged, setIsTargetOperatingSystemChanged] = useState(false);
  const [isReplacementSerialNumberChanged, setIsReplacementSerialNumberChanged] = useState(false);
  useEffect(() => {
    const normalize = (value: string | null | undefined) => value?.toUpperCase() || ""; // Normalize null/undefined to ""
    setIsGoLiveDateChanged(
      goLiveDate instanceof Date &&
        !isNaN(goLiveDate.getTime()) &&
        (device.Overrides?.GoLiveDate
          ? parseDate(device.Overrides.GoLiveDate) instanceof Date && goLiveDate.toISOString().slice(0, 10) !== parseDate(device.Overrides.GoLiveDate)?.toISOString()?.slice(0, 10)
          : device.GoLiveDate
          ? new Date(device.GoLiveDate) instanceof Date && !isNaN(new Date(device.GoLiveDate).getTime()) && goLiveDate.toISOString().slice(0, 10) !== new Date(device.GoLiveDate)?.toISOString()?.slice(0, 10)
          : true) // If neither Overrides nor device GoLiveDate are set, treat it as changed
    );

    setIsDeploymentReleaseChanged(device.Overrides?.DeploymentRelease ? deploymentRelease?.toUpperCase() !== device.Overrides.DeploymentRelease?.toUpperCase() : deploymentRelease?.toUpperCase() !== device.DeploymentRelease?.toUpperCase());
    setIsTargetOperatingSystemChanged(device.Overrides?.ISORequired ? targetOperatingSystem?.toUpperCase() !== device.Overrides.ISORequired?.toUpperCase() : targetOperatingSystem?.toUpperCase() !== device.ISORequired?.toUpperCase());
    setIsReplacementSerialNumberChanged(
      device.Overrides?.ReplacementSerialNumber ? normalize(replacementSerialNumber) !== normalize(device.Overrides.ReplacementSerialNumber) : normalize(replacementSerialNumber) !== normalize(device.ReplacementSerialNumber)
    );
  }, [device, goLiveDate, deploymentRelease, targetOperatingSystem, replacementSerialNumber]);

  // -- Track if the values are currently overridden to determine if the border should be highlighted
  const [isGoLiveDateOverrideActive, setIsGoLiveDateOverrideActive] = useState(false);
  const [isDeploymentReleaseOverrideActive, setIsDeploymentReleaseOverrideActive] = useState(false);
  const [isTargetOperatingSystemOverrideActive, setIsTargetOperatingSystemOverrideActive] = useState(false);
  const [isReplacementSerialNumberOverrideActive, setIsReplacementSerialNumberOverrideActive] = useState(false);
  useEffect(() => {
    setIsGoLiveDateOverrideActive(
      device?.Overrides?.GoLiveDate &&
        goLiveDate instanceof Date &&
        !isNaN(goLiveDate.getTime()) &&
        parseDate(device?.Overrides?.GoLiveDate) instanceof Date &&
        goLiveDate.toISOString().slice(0, 10) === parseDate(device?.Overrides?.GoLiveDate)?.toISOString()?.slice(0, 10)
    );
    setIsDeploymentReleaseOverrideActive(device?.Overrides?.DeploymentRelease && deploymentRelease === device?.Overrides?.DeploymentRelease?.toUpperCase());
    setIsTargetOperatingSystemOverrideActive(device?.Overrides?.ISORequired && targetOperatingSystem === device?.Overrides?.ISORequired?.toUpperCase());
    setIsReplacementSerialNumberOverrideActive(device?.Overrides?.ReplacementSerialNumber && replacementSerialNumber === device?.Overrides?.ReplacementSerialNumber && device?.Overrides?.ReplacementSerialNumber !== "");
  }, [device, goLiveDate, deploymentRelease, targetOperatingSystem, replacementSerialNumber]);

  // -- Function to save the device overrides
  const handleSave = async () => {
    try {
      // Update device overrides for values that have changed from the current values
      await updateDeviceOverrides(device, {
        ...(isGoLiveDateChanged ? { GoLiveDate: goLiveDate?.toISOString()?.slice(0, 10) } : {}),
        ...(isDeploymentReleaseChanged ? { DeploymentRelease: deploymentRelease } : {}),
        ...(isTargetOperatingSystemChanged ? { ISORequired: targetOperatingSystem as "Win10" | "Win11" } : {}),
        ...(isReplacementSerialNumberChanged ? { ReplacementSerialNumber: replacementSerialNumber } : {}),
      });

      closeModal(); // -- Close modal after action is complete
      extraObject.refresh(); // -- Refresh the device to show the updated values
      showToastNotification(`Overrides saved for device ${device.DeviceSerialNumber}`, "success");
    } catch (error) {
      showToastNotification(`Failed to save overrides for device ${device.DeviceSerialNumber}. Error: ${error}`, "error");
    }
  };

  // -- Determine if the save button should be enabled based on if any values have changed
  const isSaveEnabled = isGoLiveDateChanged || isDeploymentReleaseChanged || isTargetOperatingSystemChanged || isReplacementSerialNumberChanged;

  return (
    <>
      <div className="flex flex-col justify-center items-center space-y-2">
        <p className="text-center text-lg mb-5 mx-40">
          {environment?.name === "Production" ? `Overrides allow administrators to set custom values for a device.` : `Overrides allow developers and testers to set custom values for a device.`}
          <br />
          These values will be used by the agent instead of the default values from the EDP when migrating the device.
        </p>

        {/* Go Live Date */}
        <label className="form-control w-full max-w-xs">
          <div className="label ">
            <span className="label-text">Go Live Date</span>
            <span className="label-text-alt">EDP Value: {device?.GoLiveDate ? parseDate(device.GoLiveDate)?.toLocaleDateString() : "Not set"}</span>
          </div>

          <Datepicker
            value={{ startDate: goLiveDate, endDate: goLiveDate }}
            asSingle={true}
            inputClassName={`input input-bordered w-full z-50 text-white
              ${isGoLiveDateOverrideActive && "border-blue-500 shadow-lg"}
              ${isGoLiveDateChanged && "border-purple-600"}
            `}
            popoverDirection={"down"}
            onChange={(newValue) => setDeviceGoLiveDate(newValue?.startDate)}
            showShortcuts={false}
            useRange={false}
            displayFormat="DD/MM/YYYY"
            startWeekOn={"mon"}
          />
          <div className="label">
            <span></span>
            {isGoLiveDateChanged ? (
              <span className="label-text-alt">New Override Value: {goLiveDate?.toDateString() || "Not set"}</span>
            ) : (
              isGoLiveDateOverrideActive && <span className="label-text-alt">Override Active: {parseDate(device?.Overrides?.GoLiveDate)?.toDateString()}</span>
            )}
          </div>
        </label>

        {/* Deployment Release */}
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text">Deployment Release</span>
            <span className="label-text-alt">EDP Value: {device?.DeploymentRelease?.toUpperCase() || "Not set"}</span>
          </div>
          <select
            className={`
              select select-bordered
              ${isDeploymentReleaseOverrideActive && "border border-blue-500 shadow-lg"}
              ${isDeploymentReleaseChanged && "!border-purple-600"}
              `}
            value={deploymentRelease}
            onChange={(e) => setDeploymentRelease(e.target.value)}
          >
            <option disabled>Select a Deployment Release</option>
            <option value="R1">R1</option>
            <option value="R2">R2</option>
            <option value="R3">R3</option>
            <option value="R4">R4</option>
            <option value="R5">R5</option>
            <option value="R6">R6</option>
            <option value="R7">R7</option>
            <option value="R8">R8</option>
            <option value="R9">R9</option>
          </select>
          <div className="label">
            <span></span>
            {isDeploymentReleaseChanged ? (
              <span className="label-text-alt">New Override Value: {deploymentRelease || "Not set"}</span>
            ) : (
              isDeploymentReleaseOverrideActive && <span className="label-text-alt">Override Active: {device?.Overrides?.DeploymentRelease?.toUpperCase()}</span>
            )}
          </div>
        </label>

        {/* Target Operating System */}
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text">Target Operating System</span>
            <span className="label-text-alt">EDP Value: {device?.ISORequired?.toUpperCase() || "Not set"}</span>
          </div>
          <select
            className={`
              select select-bordered
              ${isTargetOperatingSystemOverrideActive && "border border-blue-500 shadow-lg"}
              ${isTargetOperatingSystemChanged && "!border-purple-600"}
              `}
            value={targetOperatingSystem}
            onChange={(e) => setTargetOperatingSystem(e.target.value)}
          >
            <option disabled>Select the target operating system</option>
            <option value="WIN10">Windows 10</option>
            <option value="WIN11">Windows 11</option>
          </select>
          <div className="label">
            <span></span>
            {isTargetOperatingSystemChanged ? (
              <span className="label-text-alt">New Override Value: {targetOperatingSystem?.toUpperCase() || "Not set"}</span>
            ) : (
              isTargetOperatingSystemOverrideActive && <span className="label-text-alt">Override Active: {device?.Overrides?.ISORequired?.toUpperCase()}</span>
            )}
          </div>
        </label>

        {/* Replacement Serial Number */}
        <label className="form-control w-full max-w-xs">
          <div className="label">
            <span className="label-text">Replacement Serial Number</span>
            <span className="label-text-alt">EDP Value: {device?.ReplacementSerialNumber?.toUpperCase() || "Not set"}</span>
          </div>
          <input
            type="text"
            className={`
              input input-bordered
              ${isReplacementSerialNumberOverrideActive ? "border border-blue-500 shadow-lg" : ""}
              ${isReplacementSerialNumberChanged && "!border-purple-600"}
              `}
            value={replacementSerialNumber?.toUpperCase()}
            onChange={(e) => setReplacementSerialNumber(e.target.value)}
          />
          <div className="label">
            <span></span>
            {isReplacementSerialNumberChanged ? (
              <span className="label-text-alt">New Override Value: {replacementSerialNumber?.toUpperCase() || "Not set"}</span>
            ) : (
              isReplacementSerialNumberOverrideActive && <span className="label-text-alt">Override Active: {device?.Overrides?.ReplacementSerialNumber?.toUpperCase()}</span>
            )}
          </div>
        </label>
      </div>

      <div className="modal-action mt-12">
        <button onClick={closeModal} className="btn btn-ghost">
          Cancel
        </button>
        <button onClick={handleSave} className={`btn btn-primary ${!isSaveEnabled && "btn-disabled"}`}>
          Save
        </button>
      </div>
    </>
  );
}
